@use '~@keyliving/component-lib/dist/scss/mixins';
@use '~@keyliving/component-lib/dist/scss/utils';
@use '~@keyliving/component-lib/dist/scss/vars';

.toggle {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    display: flex;
    width: vars.$menu-width;
    padding: 1.25rem 0;
    color: var(--layer-bg, utils.color('royal', 500));
    justify-content: center;

    @include mixins.screen('xl3') {
        display: none;
    }
}

.toggle--dark {
    --layer-bg: #{utils.color('royal', 400)};
}

.toggle:focus {
    outline: none;
}

.hamburger-wrapper {
    padding: 0.5rem;
    overflow: hidden;
    background-color: #fff;
    border-radius: 999px;
}

.hamburger-wrapper--transparent {
    background-color: transparent;
}

.hamburger {
    position: relative;
    width: 24px;
    height: 22px;

}

.hamburger__layer {
    position: absolute;
    width: 24px;
    height: 2px;
    background-color: currentcolor;
    border-radius: 1px;
    opacity: 1;
    transition: 0.25s ease-in-out;
    transform: rotate(0deg) scale(1);
}

.hamburger__layer--one {
    top: 0;
}

.hamburger__layer--two {
    bottom: 0;
}

.hamburger__layer--three,
.hamburger__layer--four {
    top: 50%;
    transform: translateY(-1px);
}

.active {
    .hamburger__layer--one,
    .hamburger__layer--two {
        top: 50%;
        opacity: 0;
        transform: scale(0);
    }

    .hamburger__layer--three {
        transform: rotate(45deg);
    }

    .hamburger__layer--four {
        transform: rotate(-45deg);
    }
}
