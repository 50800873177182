@use '~@keyliving/component-lib/dist/scss/utils' as *;
@use '~@keyliving/component-lib/dist/scss/vars';
@use '~@keyliving/component-lib/dist/scss/mixins';

#side-menu {
    @include mixins.screen('xl3') {
        /**
         * Force showing the menu on desktop as we dont have a hamburger
         * to show the menu if they get in a state where its hidden
         */
        transform: translateX(0);
    }
}

// Don't show the expand indicator if hovering the menu and it's not intentionally expanded
/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
:global(#main-menu):hover + .expand-collapse--can-vanish {
    opacity: 0;
}

#side-menu .expand-collapse {
    @include mixins.screen('xl3') {
        opacity: 1;
    }
}

.logo-wrapper {
    display: none;

    @include mixins.screen('xl3') {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 101;
        display: flex;
        width: vars.$menu-width;
        padding: 1rem 0.5rem;
        justify-content: center;
    }
}

.logo-wrapper .logo {
    margin: 0 auto;
    background-color: transparent;
}

// Needed where currentColor of button is picking up wrong color
.logo-wrapper .logo--light {
    color: color('royal', 500);
}

// Needed where currentColor of button is picking up wrong color
.logo-wrapper .logo--dark {
    color: #fff;
}

.profile-menuitem > a {
    padding-left: 0;
}