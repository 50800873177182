@use '~@keyliving/component-lib/dist/scss/vars' as *;

.wrapper {
    display: flex;
    min-height: 100vh;
    padding: 3.125rem;
    flex-direction: column;
    gap: 1rem;
}

.logo-wrapper {
    flex-grow: 0;
}

.logo {
    width: 5rem;
}

.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
}
